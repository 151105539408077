import {documentReady} from "../ReadyListener";
import $ from "jquery";
import React, { useState, useEffect } from 'react';
import ReactDOMClient from 'react-dom/client';


/**
 * Generate a simple unique ID without external libraries.
 * You can adjust this to match your needs.
 */
function generateUserId() {
  const userIdKey = "survey_id";
  
  // Check if user ID is already stored in session storage
  let userId = sessionStorage.getItem(userIdKey);
  
  // If not, generate a new one and store it
  if (!userId) {
    userId = "user_" + Date.now() + "_" + Math.floor(Math.random() * 100000);
    sessionStorage.setItem(userIdKey, userId);
  }
  
  return userId;
}

function ProgressIndicator({ currentStep, totalSteps }) {
  const progressPercentage = Math.round((currentStep / totalSteps) * 100);

  return (
    <div className="w-full mt-8 mb-4">
      {/* Progress Bar */}
      <div className="relative w-full h-4 bg-gray-300 rounded-lg">
        <div
          className="absolute top-0 left-0 h-full bg-green-600 rounded-lg transition-all"
          style={{ width: `${progressPercentage}%` }}
        />
      </div>

      {/* Textual Indicator */}
      <div className="flex justify-between mt-2 text-sm font-medium">
        <span>
          Step {currentStep} of {totalSteps}
        </span>
        <span>{progressPercentage}% Complete</span>
      </div>
    </div>
  );
}


/**
 * Final Step: Ask for email
 * We'll treat this as a separate step rather than part of QUESTIONS,
 * but you could also include it in QUESTIONS with type: "text".
 */
function Survey({texts, questions, answerUrl}) {
  const [userId] = useState(generateUserId);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [answers, setAnswers] = useState({}); // { questionId: answerValue or [answerValues], ... }
  const [email, setEmail] = useState("");
  const [finished, setFinished] = useState(false);

  // Filter out questions that do not satisfy their dependsOn conditions
  const visibleQuestions = questions.filter((q) =>
    checkDependencies(q.dependsOn, answers)
  );

  // We will treat all visibleQuestions in sequence.
  // If currentIndex >= visibleQuestions.length, we are at the final step (email).
  const isSurveyComplete = currentIndex >= visibleQuestions.length;

  useEffect(() => {
    // If the user changes an answer such that some future question is no longer visible,
    // we can reset the index if needed (e.g., clamp it to the new max visible index).
    if (currentIndex > visibleQuestions.length) {
      setCurrentIndex(visibleQuestions.length);
    }
  }, [visibleQuestions.length, currentIndex]);

  function checkDependencies(dependsOn = [], allAnswers) {
    if (!dependsOn.length) return true;
    return dependsOn.every((cond) => {
      const userAnswer = allAnswers[cond.questionId];
      // Single choice answer is a string, multiple choice answer is an array
      if (Array.isArray(userAnswer)) {
        return userAnswer.includes(cond.value);
      } else {
        return userAnswer === cond.value;
      }
    });
  }

  /**
   * Handle a user answering a single or multiple-choice question.
   * Immediately send the response to the backend.
   */
  function handleAnswer(questionId, newValue) {
    // Update answers in state
    setAnswers((prev) => ({ ...prev, [questionId]: newValue }));

    // Send to your endpoint
    sendAnswerToEndpoint({
      userId,
      questionId,
      answer: newValue,
    });
  }

  function sendAnswerToEndpoint(payload) {
    // Replace the URL below with your actual endpoint
    fetch(answerUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        // Handle success or errors
        console.log("Response sent:", payload);
      })
      .catch((err) => {
        console.error("Error sending answer:", err);
      });
  }

  function handleNext() {
    setCurrentIndex((idx) => idx + 1);
  }

  function handleBack() {
    setCurrentIndex((idx) => (idx > 0 ? idx - 1 : 0));
  }

  function handleEmailSubmit() {
    // Send the final email to endpoint or handle it as needed
    // For demonstration, we send a final payload
    sendAnswerToEndpoint({
      userId,
      questionId: "email",
      answer: email,
    });
    setFinished(true);
  }

  // Render
  return (
    <div className="flex items-center justify-center p-4">
      <div className="w-full max-w-[600px] bg-white rounded shadow p-6">

        {finished && (
          <div className="text-xl font-semibold mb-4">
            {texts.thank_you}
          </div>
        )}

        {!finished && !isSurveyComplete && (
          <QuestionStep
            question={visibleQuestions[currentIndex]}
            answer={answers[visibleQuestions[currentIndex]?.id] || null}
            onAnswer={handleAnswer}
            onNext={handleNext}
            onBack={handleBack}
            isFirstStep={currentIndex === 0}
            texts={texts}
          />
        )}

        {/* Final Step: Ask for Email */}
        {!finished && isSurveyComplete && (
          <div>
            <div className="text-xl font-semibold mb-4">
              {texts.enter_email}
            </div>
            <input
              type="email"
              className="border border-gray-300 rounded px-3 py-2 w-full mb-4"
              placeholder={texts.your_email}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setCurrentIndex(currentIndex - 1)}
                className="inline-flex items-center justify-center px-5 h-12 rounded bg-gray-200 hover:bg-gray-300 text-gray-800 mr-4"
              >
                {texts.back}
              </button>
              <button
                onClick={handleEmailSubmit}
                className="inline-flex items-center justify-center px-5 h-12 rounded bg-actidoo-red-primary text-white hover:bg-actidoo-red-400  "
              >
                {texts.submit}
              </button>
            </div>
          </div>
        )}

        <ProgressIndicator currentStep={currentIndex} totalSteps={visibleQuestions.length} />
      </div>
    </div>
  );
}

/**
 * Individual Step Component
 */
function QuestionStep({ question, answer, onAnswer, onNext, onBack, isFirstStep, texts }) {
  if (!question) return null;

  const { id, type, question: questionText, answers } = question;

  function handleSelectSingle(val) {
    onAnswer(id, val);
  }

  function handleSelectMultiple(val) {
    // If the answer is not an array yet, make it one
    const current = Array.isArray(answer) ? answer : [];
    let newAnswer;
    if (current.includes(val)) {
      // Remove it
      newAnswer = current.filter((v) => v !== val);
    } else {
      // Add it
      newAnswer = [...current, val];
    }
    onAnswer(id, newAnswer);
  }

  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">{questionText}</h2>

      {/* Render according to question type */}
      {type === "single" && (
        <div className="flex flex-col gap-2 mb-4">
          {answers.map((option) => (
            <label key={option.value} className="inline-flex items-center gap-2">
              <input
                type="radio"
                name={`q-${id}`}
                value={option.value}
                checked={answer === option.value}
                onChange={() => handleSelectSingle(option.value)}
                className="form-radio"
              />
              <span>{option.label}</span>
            </label>
          ))}
        </div>
      )}

      {type === "multiple" && (
        <div className="flex flex-col gap-2 mb-4">
          {answers.map((option) => (
            <label key={option.value} className="inline-flex items-center gap-2">
              <input
                type="checkbox"
                name={`q-${id}`}
                value={option.value}
                checked={Array.isArray(answer) && answer.includes(option.value)}
                onChange={() => handleSelectMultiple(option.value)}
                className="form-checkbox"
              />
              <span>{option.label}</span>
            </label>
          ))}
        </div>
      )}

      {/* Navigation Buttons */}
      <div className="flex justify-between">
        {/* Back button */}
        {!isFirstStep && (
          <button
            onClick={onBack}
            className="inline-flex items-center justify-center px-5 h-12 rounded bg-gray-200 hover:bg-gray-300 text-gray-800 mr-4"
          >
            {texts.back}
          </button>
        )}
        <div className="ml-auto">
          {/* Skip button (always available) */}
          <button
            onClick={onNext}
            className="inline-flex items-center justify-center px-5 h-12 rounded bg-gray-200 hover:bg-gray-300 text-gray-800 mr-4"
          >
            {texts.skip}
          </button>
          {/* Next button */}
          <button
            onClick={onNext}
            className="inline-flex items-center justify-center px-5 h-12 rounded bg-actidoo-red-primary text-white hover:bg-actidoo-red-400"
          >
            {texts.next}
          </button>
        </div>
      </div>
    </div>
  );
}


  documentReady((body) => {

    $(body).findWithRoot(".c-package-wizard").each((i,wizardEl)=> {
        if (!wizardEl.setupDone) {
            wizardEl.setupDone = true;

            const texts = $(wizardEl).data("texts");
            const questions = $(wizardEl).data("questions");
            const answerUrl = $(wizardEl).data("answer-url");

            const root = ReactDOMClient.createRoot(wizardEl);
            const app = <Survey texts={texts} questions={questions.questions} answerUrl={answerUrl} />;
            root.render(app);
        }
    })

  });
